<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="600px">
    <div class="request-box">
      <el-form ref="form" :model="request" label-width="60px" style="flex: 1; margin-top: 15px">
        <el-form-item label="手机号" :error="errors.phone">
          <el-input
            v-model="request.phone"
            :maxlength="11"
            :minlength="11"
            @input="onPhoneInput"
            placeholder="请输入申请手机号"></el-input>
        </el-form-item>
        <el-form-item label="验证码" :error="errors.code">
          <el-input
            v-model="request.code"
            placeholder="请输入验证码"
            class="input-code"
            name="code"
            type="text"
            tabindex="2">
            <el-button :loading="loading.send" slot="append" @click="sendSMSCode">{{codeContent}}</el-button>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doSubmit" style="width: 100%;">{{readerNumber ? "开始阅读 (序号：" + readerNumber +")" : "提交申请"}}</el-button>
        </el-form-item>
      </el-form>
      <div style="padding-left: 20px; margin-left: 20px; border-left: 1px solid #9c9c9c;">
        <div class="qr-box">
          <qr v-if="requestQrUrl" :margin="10" :text="requestQrUrl" :size="200"></qr>
          <div v-if="!requestQrUrl">二维码加载中..</div>
        </div>
      </div>
    </div>
    <ReadFile ref="readFile" />
  </el-dialog>
</template>

<script>
import qr from 'vue-qr'
import { requestAPI} from 'liankong-ui-api' 
import ReadFile from "@/components/readFile.vue"
import { Store } from '@/utils/LocalCache.js'
export default {
  props: {
  },
  components: {
    qr,ReadFile
  },
  data () {
    return {
      title: "申请授权",
      mode: null,

      visible: false,
      rules: {
        phone: [ {required: true, message: "请输入手机号"}, 
                 { pattern: /^1\d{10}$/, message: '请输入正确的手机号'}],
        code: [ {required: true, message: "请输入验证码"}],
      },
      
      /**
      * 0 直接申请
      * 1 代理申请
      */ 
      requestType: 0,
      request: {
        shareId: null,
        documentId: null,
        phone: null,
        code: null
      },
      codeContent: "获取验证码",
      loading: {
        send: false
      },
      timerRunning: false,
      errors: {
        phone: null,
        code: null
      },
      readerNumber: null, //阅读序号
      
      //扫码授权地址
      clientId: (+new Date()),
      client: null,
      requestQrUrl: null,
      fileInfo:{
        shareId:null,
        documentId:null,
        documentRootId:null
      }
    }
  },
  computed: {
    userInfo(){
      return {
        phoneNumber: null
      }
    }
  },
  watch: {},
  methods: {
    show(title, mode, shareId, documentId, urlKey,documentRootId){
      this.title = title
      this.mode = mode
      this.fileInfo={
        shareId,
        documentId,
        documentRootId
      }
      
      this.shareId = shareId
      this.documentId = documentId  //申请模式有文档id， 订阅模式没有
      this.urlKey = urlKey

      if(this.mode == 'request'){
        this.doCreateClient()
      }

      this.visible = true
    },

    doCreateClient(){
      this.requestQrUrl = this.$options.filters["shareUrl"](this.$route.params.url);
    },
    doSubmit(){
      if(this.checkPhone()){
        if(!this.request.code){
          this.errors.code = "请输入验证码"
          return
        }

        if(this.readerNumber){
          //有阅读码序号
          let {shareId,documentId,documentRootId} = this.fileInfo
          requestAPI.checkReadCode(this.request.phone,this.request.code,this.readerNumber,shareId,documentId,documentRootId)
          .then(({code, data, message}) => {
            if(code == 0){
              // window.open(this.$options.filters["readerUrl"](data.url))
              let shareInfoGather = `&shareIds=${shareId}&documentId=${documentId}&documentRootId=${documentRootId}&phoneNumber=${this.request.phone}`
              if(Store.get("myPreference")){
                  if(Store.get("myPreference").reader_model == '1'){
                      window.open(this.$options.filters['readerUrl'](data.url,shareInfoGather));
                  }else{
                      this.$refs['readFile'].showReadFile(this.$options.filters['readerUrl'](data.url,shareInfoGather))
                  }
                }else{
                    this.$refs['readFile'].showReadFile(this.$options.filters['readerUrl'](data.url,shareInfoGather))
                }
              // this.$refs['readFile'].showReadFile(this.$options.filters['readerUrl'](data.url,shareInfoGather))
              this.visible = false
            } else {
              this.$message.error(message)
            }
          })
        }else{
          //提交申请
          requestAPI.create({
            phone: this.request.phone,
            code: this.request.code,
            shareId: this.shareId
          }).then(({code, data, message}) => {
            if(code == 0){
              this.$message.success("申请提交成功!")
              this.visible = false
            } else {
              this.$message.error(message)
            }
          })
        }
      }
    },
    checkPhone(){

      if(!this.request.phone){
        this.errors.phone = "请输入手机号"
        return false
      }else if(!/^1\d{10}$/.test(this.request.phone)){
        this.errors.phone = "请输入正确的手机号"
        return false
      }

      return true
    },
    sendSMSCode(){
      if(this.loading.send || this.timerRunning) return

      this.readerNumber = null
      this.errors.phone = null

      if(this.checkPhone()){
        this.loading.send = true
        requestAPI.getSMSCodeByShare(this.shareId,this.documentId,this.request.phone,this.fileInfo.documentRootId
        ).then(({code, data, message}) => {
          this.loading.send = false
          this.countDownChange()
          if(code==0){
            //阅读码
            if(data){
              this.readerNumber = data
            }
          } else {
            this.$message.error(message)
          }
        }).catch(err => {
          this.loading.send = false
        })
      }
    },
    //倒计时
    countDownChange(){
      let number=60;
      this.timerRunning = true
      this.loading.send = false;
      this.codeContent="60S后重新获取";
      let timer = setInterval(() => {
        if(number>1){
          number--;
          this.codeContent=number+"S后重新获取";
          return true;
        }else{
          number = 60;
          clearInterval(timer);
          this.codeContent="获取验证码";
          this.timerRunning = false
          return false;
        }
      }, 1000);
    },

    //手机号输入
    onPhoneInput(value){
      this.requestType = value.length == 11 && value != this.userInfo.phoneNumber ? 1 : 0
    }
  },
  created () {
    
  },
  mounted () {
    
  },
}
</script>
<style lang='less' scoped>
.request-box{
  display: flex;
  margin-top: -20px;

  .qr-box{
    /* border: 1px #000 solid; */
    width: 200px;
    height: 200px;
    line-height: 200px;
    text-align: center;
  }
}
</style>