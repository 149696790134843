<template>
  <el-table
    element-loading-text="数据加载中"
    element-loading-spinner="el-icon-loading"
    :data="showFiles"
    style="width: 100%"
    row-key="id"
    row-class-name="document-item">
    <el-table-column
      prop="name"
      label="文件名称">
      <template slot-scope="scope">
        <div class="file-column-name">
          <span style="cursor: pointer; line-height: 26px;" @click="enterFolder(scope.row)">
            <i class="el-icon-folder-opened" v-if="scope.row.type == 0" style="font-size: 16px; margin-right: 5px;"></i> 
            <i class="el-icon-document" v-if="scope.row.type == 1" style="font-size: 16px; margin-right: 5px;"></i> 
            {{scope.row.documentName}}
          </span>
        </div>
      </template>
    </el-table-column>
    <el-table-column
      label="类型"
      width="120">
      <template slot-scope="scope">
        <span>{{scope.row.type|fileType(scope.row)}}</span>
      </template>
    </el-table-column>
    <el-table-column
      label="大小"
      width="130">
      <template slot-scope="scope">
        <span>{{ scope.row.size | byteSize }}</span>
      </template>
    </el-table-column>
    <el-table-column
      label="修改日期"
       align="center"
      width="160">
      <template slot-scope="scope">
        <span>{{ scope.row.updateDate|date }}</span>
      </template>
    </el-table-column>
    <el-table-column
      label="操作"
      align="center"
      width="150">
      <template slot-scope="scope">
        <el-button
          v-if="scope.row.type == 1"
          size="mini"
          type="primary"
          @click="doReader(scope.$index, scope.row)">阅读</el-button>
        <el-button
          v-if="checkShow(scope.row)"
          size="mini"
          type="primary"
          @click="doSave(scope.row)">保存</el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { Store } from '@/utils/LocalCache.js'
export default {
  components: {},
  props: {
    showFiles: {
      type: Array,
      default: []
    },
    shareInfo:{
      type: Object,
      default:{}
    }
  },
  filters: {
    fileType(value, row){
      return value == 1 ?  row.suffix : "文件夹"
    }
  },
  data () {
    return {

    }
  },
  computed: {
    loading(){
      return this.$store.state.document.viewer.loading
    }
  },
  watch: {},
  methods: {
    checkShow(row){
      return row.type == 1 &&  this.shareInfo && this.shareInfo.canArchive && Store.get('access_token')
    },
    enterFolder(row){
      if(row.type == 0){
        this.$emit("onEnter", row)
      }
    },

    doReader(index, row){
      this.$emit("onReader", row)
    },
    doSave(row){
      this.$emit("doSave", row)
    }
  },
  created () {

  },
  mounted () {

  },
}
</script>
<style lang='less' scoped>
.file-column-name{
  display: flex;
  justify-content: space-between;
  margin-right: 50px;

  a{
    color: initial;
    text-decoration: none;
  }

  .btns{
    font-size: 16px;
    display: none;

    i{
      display: inline-block;
      padding: 5px;
      margin: 0 2px;
      cursor: pointer;

      &.disable{
        color: #cccccc;
      }
    }
  }
}

.document-item{
  &:hover{
    .btns{
      display: inline-block;
    }
  }
}
</style>