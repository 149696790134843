<template>
	<div v-loading="loading" element-loading-text="共享信息加载中.." class="loading">
		<h3  v-if='!isApp'>{{shareInfo.name}}</h3>
		<div v-if="isApp" class="app-container">
			<div class="top-logo">
				<img src="@/assets/logo.png" alt="友虹文档中台">友虹文档中台
			</div>
			<div class="name-box">
				<i class="el-icon-user-solid"></i> {{shareInfo.ownerName | mask}} 的文件共享
			</div>
			<ul class="file-box">
				<li v-for="item in files" :key='item.id' @click="onReader(item)">
					<div>
						<img :src="thumbIcon(item)" alt="">
					</div>
					<div>
						<p>{{item.name}}</p>
						<p><span>{{item.suffix}}</span> | {{item.createDate|date}}</p>
					</div>
				</li>
			</ul>
		</div>
		<div v-if='!isApp'>
			<p>{{shareInfo.description}}</p>
			<div style="display: flex; justify-content: space-between; line-height: 40px;">
				<span style="color: #ababab; font-size: 14px;">
					{{shareInfo.ownerName | mask}} 共享于 {{shareInfo.createDate|date}}
				</span>
				<div>
				<el-button type="info" v-if="goRoot" @click="goRootHanler">返回根目录</el-button>
				<!-- <el-button type="default" @click="doSubscribe" v-if="hasFolder">订阅</el-button> -->
				</div>
			</div>
		</div>
		<ShareFileList  v-if='!isApp' :showFiles="files" :shareInfo='shareInfo' @onReader="onReader" @onEnter="onEnter" @doSave='doSave'></ShareFileList>
		<ShareRequestDialog ref="requestDialog"></ShareRequestDialog>
		<ReadFile ref="readFile" />
		<!-- 选择我的文档 -->
      	<SelectMyDocument ref='SelectMyDocument' @SelectMyDocument='SelectMyDocument'></SelectMyDocument>
	</div>
</template>

<script>
import { shareAPI,documentAPI,requestAPI } from 'liankong-ui-api'
import ShareFileList from './ShareFileList.vue'
import ShareRequestDialog from './ShareRequest.vue'
import ReadFile from "@/components/readFile.vue"
import { Store } from '@/utils/LocalCache.js'
import iconFolder from '@/assets/img/folder.png'
import iconDefault from '@/assets/img/icon-def.png'
import iconUnknown from '@/assets/img/icon-unknown.png'
import iconImage from '@/assets/img/icon-picture.png'
import iconOFD from '@/assets/img/icon-ofd.png'
import iconAudio from '@/assets/img/icon-audio.png'
import iconWord from '@/assets/img/icon-word.png'
import iconPpt from '@/assets/img/icon-ppt.png'
import iconPdf from '@/assets/img/icon-pdf.png'
import iconExcl from '@/assets/img/icon-excl.png'
import iconOrg from '@/assets/img/org.png'
import iconMP3 from '@/assets/doc-icon/mp3.png'
import iconMP4 from '@/assets/doc-icon/mp4.png'
import iconZIP from '@/assets/doc-icon/zip.png'
import iconDepart from '@/assets/img/depart.png'
import SelectMyDocument from '../onlineEditing/components/SelectMyDocument.vue'


//后缀映射
const suffixIconCache = {
  def: iconDefault,
  folder: iconFolder,
  unknown: iconUnknown,
  org:iconOrg,
  depart:iconDepart,
  png: iconImage,
  jpg: iconImage,
  jpeg: iconImage,
  bmp: iconImage,

  ofd: iconOFD,

  doc: iconWord,
  DOC: iconWord,
  docx: iconWord,

   mp3: iconMP3,
  mp4: iconMP4,
  zip: iconZIP,
  ppt:iconPpt,
  pptx:iconPpt,
  pdf:iconPdf,
  xls:iconExcl,
  xlsx:iconExcl
}

export default {
	components: {
		ShareFileList,
		ShareRequestDialog,
		ReadFile,
		SelectMyDocument
	},
	data () {
		return {
			loading: true,
			shareUrl: null,
			rootFiles: [],
			files: [],
			shareInfo: {},
			goRoot: false, //返回根目录

			hasFolder: false,
			documentType:'',
			currentDocument:{
				documentId:null,
				documentRootId:null,
				shareId:null,
				userDocumentFolderId:null
			}
		}
	},
	computed: {
		isApp(){
			return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
		}
	},
	watch: {},
	methods: {
		  /**
         * 图标
         */
        thumbIcon(doc){
            let iconKey = "unknown"
            if(doc.documentType==1 &&doc.orgId!=null){
                iconKey='org'
            }else if(doc.documentType == 2 &&doc.orgId!=null){
                iconKey='depart'
            }else{
                if(doc.type == 0){
                    iconKey = "folder"
                }else if(suffixIconCache.hasOwnProperty(doc.suffix)){
                    iconKey = doc.suffix
                }
            }

            return suffixIconCache[iconKey] 
        },
		loadShareInfo(){
			if(this.shareUrl){
				shareAPI.detailByUrl(this.shareUrl).then(({code, data, message}) => {
					
					if(code == 0){
						this.shareInfo = data
						this.files = data.resources.sort((a,b) => a.type - b.type).map(item => {
						item.rootId = item.documentId;

						//如果存在文件夹，则有订阅
						if(item.type == 0){
							this.hasFolder = true
						}

						return item
						})
						//保存根指针
						this.rootFiles = this.files
						this.loading = false
					}else{
						this.loading = false;
						this.$message.error(message)
					}
				}).catch(err => {
					this.$confirm('登录失效请刷新页面，是否继续?','提示',{
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(()=>{
						this.$store.commit("login/setLoginInfo", {user_info:null});
						window.location.reload()
					})
					
					this.loading = false
				})
			}else{
				this.loading = false
				this.$alert("您来晚了，当前共享已经失效", "系统提示")
			}
		},

		doSave(file){
			this.currentDocument ={
				documentId:file.documentId||file.id,
				documentRootId:this.documentType||file.documentId,
				shareId:this.shareInfo.id,
				userDocumentFolderId:null
			}
			this.$refs.SelectMyDocument.show('folder',file.id,'shareToMe');
			
		},
		 // 保存到我的文档
		SelectMyDocument(parentId){
			if(parentId != null ){
				this.currentDocument.userDocumentFolderId = parentId;
				documentAPI.shareArchiveDocument(this.currentDocument).then(res => {
					if(res.code == 0){
						this.$message.success('保存成功，请到我的文档中查看!')
					}else{
						this.$message.error(res.message)
					}
				})
			}
			
		},

		onReader(file){
			let suffix = 'ofd'
			/**
			 * 和后端沟通逻辑分为三层
			 * 首先先阅读文件(考虑公开文档),如果文档不是自己并且文档转成功 code为1001
			 * 再次判断当前用户有没有登录链控
			 * 登录--->判断是长城版本-----> 快速申请
			 * 登录--->判断不是长城版本-----> 弹框申请
			 * 未登录  到登录页面
			 */
			shareAPI.getReaderUrl(this.shareInfo.id, file.documentId || file.id, this.documentType||file.documentId,'',suffix)
				.then(({ code, data, message }) => {
					if (code == 0) {
						//获取到阅读地址，跳转阅读服务
						let shareInfoGather = `&shareIds=${this.shareInfo.id}&documentId=${file.documentId || file.id}&documentRootId=${this.documentType||file.documentId}`
						if(this.isApp){
							let name = encodeURI(file.name)
							location.href= this.$options.filters['readerUrl'](data.url,shareInfoGather+`&documentName=${name}`)
						}else{
							if(Store.get("myPreference")){
								if(Store.get("myPreference").reader_model == '1'){
									window.open(this.$options.filters['readerUrl'](data.url,shareInfoGather));
								}else{
									this.$refs['readFile'].showReadFile(this.$options.filters['readerUrl'](data.url,shareInfoGather))
								}
							}else{
								this.$refs['readFile'].showReadFile(this.$options.filters['readerUrl'](data.url,shareInfoGather))
							}
						}
						
					} else if(code==1001){
						if(Store.get('access_token')){
							this.$confirm('您没有阅读该文档的权限, 是否快速申请?', '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {
								requestAPI.createByLoginUser(this.shareInfo.id)
								.then(({code, data, message}) => {
									if(code == 0){
									this.$message.success('已提交阅读申请')
									}else{
									this.$message.error(message)
									}
								}).catch(err => {
									this.$message.error(message)
								})
							}).catch(() => {});
						} else {
							if(!this.$isGreadWallVersion || this.isApp){    
								this.$router.push(`/login?redirect=${this.$route.path}`)                   
							} else {
								this.$refs["requestDialog"].show("申请阅读权限", "request", this.shareInfo.id, file.documentId, this.shareUrl,this.documentType||file.documentId)
							}
						}
					} else {
						this.$message.error(message)
					}
				}).catch(err => {
					this.$message.error("阅读请求发送失败，请稍后重试!")
				})    
		},

		onEnter(folder){
			let {documentId} = folder
			documentAPI.queryShareChildren({
				id:documentId
			}).then(({code, data, message}) => {
				if(code == 0){
				this.goRoot = true
				if(this.documentType==''&&this.goRoot){
					this.documentType=documentId
				}
				this.files = data.map(item => {
					item.documentId = item.id
					item.documentName = item.name
					item.rootId = folder.rootId
					return item
				})
				}
			})
		},

		goRootHanler(){
			this.goRoot = false
			this.files = this.rootFiles
		},

		//订阅
		doSubscribe(){
			subscribe(this.shareInfo.id).then(data => {
				if(data.code == 0){
				//需要订阅
				this.$refs["requestDialog"].show("订阅共享", "subscribe", this.shareInfo.id)
				}else{
				//不需要订阅
				this.$message.success("您已订阅了该资源!")
				}
			})
		}
	},
	created () {
		this.shareUrl = this.$route.params.url;
		this.loadShareInfo()
	},
	mounted () {

	},
}
</script>
<style lang='less' scoped>
.loading {
  padding: 0 0px;
  box-sizing: border-box;
}
.app-container{
	color: #333;
	padding: 0;
	.top-logo{
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: bold;
		color: #333;
		font-family: cursive;
		font-size: 34px;
		img{
			width: 50px;
			height: auto;
			margin-right: 5px;
		}
	}
	.name-box{
		text-align: center;
		margin: 30px 0;
	}
	.file-box{
		list-style: none;
		padding: 0;
		margin: 0;
		height: 400px;
		overflow: scroll;
		li{
			background: #f5f6f9;
			padding: 10px 15px;
			color: #666;
			display: flex;
			box-sizing: border-box;
			justify-content: flex-start;
			align-items: center;
			p{
				margin: 5px 0;
				color: #999;
			}
			p:nth-child(1){
				 overflow: hidden;
				-webkit-line-clamp: 2;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				line-height:20px;
				color: #666;
				margin-top: 0;
			}
			div:nth-child(1){
				width: 50px;
			}
			div:nth-child(2){
				width: calc(100% - 50px);
			}
			img{
				width: 40px;
				height: auto;
				margin-right: 10px;
			}
		}
	}

}


</style>