import { render, staticRenderFns } from "./ShareReader.vue?vue&type=template&id=53eb8225&scoped=true&"
import script from "./ShareReader.vue?vue&type=script&lang=js&"
export * from "./ShareReader.vue?vue&type=script&lang=js&"
import style0 from "./ShareReader.vue?vue&type=style&index=0&id=53eb8225&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53eb8225",
  null
  
)

export default component.exports